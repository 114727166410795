<template>
    <PageContent>
        <NotReady v-if="!ready" />
        <div v-else>
            <PageTitle>Order Summary for 
                {{$route.query.FirstDate?$root.moment($route.query.FirstDate,'MM/DD/YYYY').format('MM/DD/YYYY'):$root.moment().startOf('week').day(0).format('MM/DD/YYYY')}} 
                thru 
                {{$route.query.FirstDate?$root.moment($route.query.FirstDate,'MM/DD/YYYY').endOf('week').format('MM/DD/YYYY'):$root.moment().endOf('week').format('MM/DD/YYYY')}} 
            <Help /></PageTitle>
            <div class="row">
                <div class="col text-center">
                    <a :href="`/OrderSummary/?SummaryType=${$route.query.SummaryType?$route.query.SummaryType:'LOCATION'}&FirstDate=${
                        ($route.query.FirstDate?
                            $root.moment($route.query.FirstDate,'MM/DD/YYYY').subtract(1,'week').format('MM/DD/YYYY'):
                            $root.moment().subtract(1,'week').day(0).format('MM/DD/YYYY'))
                    }`" class="btn btn-secondary btn-sm ms-1 me-1 mt-1 mb-1 d-block d-sm-inline-block" data-intro="View previous week">Previous Week</a>

                    <a :href="`/OrderSummary/?SummaryType=LOCATION&FirstDate=${$route.query.FirstDate?$route.query.FirstDate:$root.moment().startOf('week').day(0).format('MM/DD/YYYY')}`" class="btn btn-secondary btn-sm  ms-1 me-1 mt-1 mb-1 d-block d-sm-inline-block"  data-intro="View orders by location">Location Level</a>

                    <a :href="`/OrderSummary/?SummaryType=ITEM&FirstDate=${$route.query.FirstDate?$route.query.FirstDate:$root.moment().startOf('week').day(0).format('MM/DD/YYYY')}`" class="btn btn-secondary btn-sm  ms-1 me-1 mt-1 mb-1 d-block d-sm-inline-block" data-intro="View ordered items">Item Level</a>

                    <a :href="`/OrderSummary/?SummaryType=${$route.query.SummaryType?$route.query.SummaryType:'LOCATION'}&&FirstDate=${
                        ($route.query.FirstDate?
                            $root.moment($route.query.FirstDate,'MM/DD/YYYY').add(1,'week').format('MM/DD/YYYY'):
                            $root.moment().add(1,'week').day(0).format('MM/DD/YYYY'))
                    }`" class="btn btn-secondary btn-sm  ms-1 me-1 mt-1 mb-1 d-block d-sm-inline-block"  data-intro="View next week">Next Week</a>

                </div>
            </div>
            <hr>
            <div class="text-end mb-2">
                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="printList" data-bs-toggle="dropdown" aria-expanded="false" data-intro="View PDF">
                        Print
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="printList">
                        <li v-if="!$route.query.SummaryType||$route.query.SummaryType=='LOCATION'"><a target="_blank"  class="dropdown-item" :href="`${$store.state.api}/pdfs/ordersummaryLocation.php?lccustno=${$root.getCustomerNumber()}&token=${$store.state.localStorage.token}&SummaryType=LOCATION&FirstDate=${$root.moment($route.query.FirstDate).day(0).format('MM/DD/YYYY')}&LastDate=${$root.moment().endOf('week').format('MM/DD/YYYY')}`">PDF</a></li>
                        <li v-if="$route.query.SummaryType=='ITEM'"><a target="_blank"  class="dropdown-item" :href="`${$store.state.api}/pdfs/ordersummaryItem.php?lccustno=${$root.getCustomerNumber()}&token=${$store.state.localStorage.token}&SummaryType=${$route.query.SummaryType}&FirstDate=${$root.moment($route.query.FirstDate).day(0).format('MM/DD/YYYY')}&LastDate=${$root.moment().endOf('week').format('MM/DD/YYYY')}`">PDF</a></li>
                    </ul>
                </div>
            </div>
            <ScrollingTable data-title="OrderSummary" :style="`position:sticky; top: 0; left: 0`" :scroll="true" v-if="$route.query.SummaryType==undefined||$route.query.SummaryType=='LOCATION'">
                <template v-slot:table-header>
                    <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                        <thead>
                            <tr data-intro="Use the arrow to sort each column alphabetically or numerically.">
                                <th title="Route" class="text-white" style=" background-color: var(--bs-gray-700); width: 80px;">
                                    <span><small>Route</small></span>
                                    <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='route no_'" :class="`${(filter.orderBy=='route no_'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='route no_'?'':'rotate')}`">
                                </th>
                                <th title="Location" class="text-white"  style=" background-color: var(--bs-gray-700); width: 110px;">
                                    <span><small>Location</small></span>
                                    <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='location no_'" :class="`${(filter.orderBy=='location no_'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='location no_'?'':'rotate')}`">
                                </th>
                                <th title="Description" class="text-white"  style=" background-color: var(--bs-gray-700); width: 145px;">
                                    <span><small>Description</small></span>
                                    <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='name'" :class="`${(filter.orderBy=='name'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='name'?'':'rotate')}`">
                                </th>
                                <th title="Monday" class="text-white"  style=" background-color: var(--bs-gray-700); width: 110px;">
                                    <span><small>Monday</small></span>
                                    <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='monday_count'" :class="`${(filter.orderBy=='monday_count'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='monday_count'?'':'rotate')}`">
                                </th>
                                <th title="Tuesday" class="text-white"  style=" background-color: var(--bs-gray-700); width: 110px;">
                                    <span><small>Tuesday</small></span>
                                    <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='tuesday_count'" :class="`${(filter.orderBy=='tuesday_count'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='tuesday_count'?'':'rotate')}`">
                                </th>
                                <th title="Wednesday" class="text-white"  style=" background-color: var(--bs-gray-700); width: 110px;">
                                    <span><small>Wednesday</small></span>
                                    <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='wednesday_count'" :class="`${(filter.orderBy=='wednesday_count'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='wednesday_count'?'':'rotate')}`">
                                </th>
                                <th title="Thursday" class="text-white"  style=" background-color: var(--bs-gray-700); width: 110px;">
                                    <span><small>Thursday</small></span>
                                    <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='thursday_count'" :class="`${(filter.orderBy=='thursday_count'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='thursday_count'?'':'rotate')}`">
                                </th>
                                <th title="Friday" class="text-white"  style=" background-color: var(--bs-gray-700); width: 110px;">
                                    <span><small>Friday</small></span>
                                    <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='friday_count'" :class="`${(filter.orderBy=='friday_count'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='friday_count'?'':'rotate')}`">
                                </th>
                                <th title="Friday" class="text-white"  style=" background-color: var(--bs-gray-700); width: 110px;" v-if="canOrderSaturday">
                                    <span><small>Saturday</small></span>
                                    <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='friday_count'" :class="`${(filter.orderBy=='friday_count'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='friday_count'?'':'rotate')}`">
                                </th>
                                <th title="Total" class="text-white"  style=" background-color: var(--bs-gray-700); width: 110px;">
                                    <span><small>Total</small></span>
                                    <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='total'" :class="`${(filter.orderBy=='total'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='total'?'':'rotate')}`">
                                </th>
                            </tr>
                            <tr data-intro="Use these inputs and toggles to filter the results of the table.">
                                <th  style="background-color:var(--bs-gray-500);"><input autocomplete="off" autosuggest="off" @input="filterInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['route no_']"></th>
                                <th  style="background-color:var(--bs-gray-500);"><input autocomplete="off" autosuggest="off" @input="filterInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['location no_']"></th>
                                <th  style="background-color:var(--bs-gray-500);"><input autocomplete="off" autosuggest="off" @input="filterInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['name']"></th>
                                <th  style="background-color:var(--bs-gray-500);">
                                    <div class="d-block form-check form-switch text-center">
                                        <input type="checkbox" v-model="search['monday_count']" class="form-check-input float-none">
                                    </div>
                                </th>
                                <th  style="background-color:var(--bs-gray-500);">
                                    <div class="d-block form-check form-switch text-center">
                                        <input type="checkbox" v-model="search['tuesday_count']" class="form-check-input float-none">
                                    </div>
                                </th>
                                <th  style="background-color:var(--bs-gray-500);">
                                    <div class="d-block form-check form-switch text-center">
                                        <input type="checkbox" v-model="search['wednesday_count']" class="form-check-input float-none">
                                    </div>
                                </th>
                                <th  style="background-color:var(--bs-gray-500);">
                                    <div class="d-block form-check form-switch text-center">
                                        <input type="checkbox" v-model="search['thursday_count']" class="form-check-input float-none">
                                    </div>
                                </th>
                                <th  style="background-color:var(--bs-gray-500);">
                                    <div class="d-block form-check form-switch text-center">
                                        <input type="checkbox" v-model="search['friday_count']" class="form-check-input float-none">
                                    </div>
                                </th>
                                <th  style="background-color:var(--bs-gray-500);" v-if="canOrderSaturday">
                                    <div class="d-block form-check form-switch text-center">
                                        <input type="checkbox" v-model="search['saturday_count']" class="form-check-input float-none">
                                    </div>
                                </th>
                                <th  style="background-color:var(--bs-gray-500);">
                                    <div class="d-block form-check form-switch text-center">
                                        <input type="checkbox" v-model="search['total']" class="form-check-input float-none">
                                    </div>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </template>
                <template  v-slot:table-body>
                    <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                        <tbody>
                            <tr v-for="(row, i) in _.filter(_.orderBy(rows, filter.orderBy, filter.order), r=>{
                                return r['route no_'].toLowerCase().startsWith(search['route no_'].toLowerCase().trim())&&
                                r['location no_'].toLowerCase().startsWith(search['location no_'].toLowerCase().trim())&&
                                new RegExp(search['name'].toLowerCase().trim(), 'gi').test(r['name'].toLowerCase())&&
                                (search['monday_count']?r['monday_count']!='0':(r['monday_count']!='0'||r['monday_count']=='0'))&&
                                (search['tuesday_count']?r['tuesday_count']!='0':(r['tuesday_count']!='0'||r['tuesday_count']=='0'))&&
                                (search['wednesday_count']?r['wednesday_count']!='0':(r['wednesday_count']!='0'||r['wednesday_count']=='0'))&&
                                (search['thursday_count']?r['thursday_count']!='0':(r['thursday_count']!='0'||r['thursday_count']=='0'))&&
                                (search['friday_count']?r['friday_count']!='0':(r['friday_count']!='0'||r['friday_count']=='0'))&&
                                (search['saturday_count']?r['saturday_count']!='0':(r['saturday_count']!='0'||r['saturday_count']=='0'))&&
                                (search['total']?r['total']!='':(r['total']!=''||r['total']==''));
                            })" :key="i">
                                <td style="width: 80px;">{{row['route no_']}}</td>
                                <td style="width: 110px;">{{row['location no_']}}</td>
                                <td style="width: 145px;">{{row['name']}}</td>
                                <td style="width: 110px;" :data-date="$root.moment($route.query.FirstDate, 'MM/DD/YYYY').day(1).format('MM/DD/YYYY')">
                                    <div v-if="row['past_add_cut']['M']=='false'">
                                        <!-- not past add cut -->
                                        <div v-if="row.monday_count!='0'"><a :href="`#`" @click.prevent.stop="(showDayView={orders:[], dow:'monday', ...row})">View ({{row.monday_count}})</a></div>
                                        <div v-else-if="$root.getCustomerValue('Web Orders Allowed Count')>0" >
                                            <!-- <a :href="`/orders/${row['route no_']}/${row['location no_']}/create/edit?FirstDate=${$root.moment($route.query.FirstDate, 'MM/DD/YYYY').day(1).format('MM/DD/YYYY')}`"  @click.prevent.stop="checkAccountStatus">Create</a> -->
                                            <router-link v-if="checkAccountStatusBool()" :to="`/orders/${row['route no_']}/${row['location no_']}/create/edit?FirstDate=${$root.moment($route.query.FirstDate, 'MM/DD/YYYY').day(1).format('MM/DD/YYYY')}`" @click.prevent.stop="checkAccountStatus">Create</router-link>
                                            <div v-else><a href="#" @click.prevent.stop="checkAccountStatus">Create</a></div>
                                        </div>

                                    </div>
                                    <div v-else>
                                        <!-- past ad cut -->
                                        <div v-if="row.monday_count!='0'"><a :href="`#`" @click.prevent.stop="(showDayView={orders:[], dow:'monday', ...row})">View ({{row.monday_count}})</a></div>
                                        <div v-else></div>
                                    </div>
                                </td>
                                <td style="width: 110px;" :data-date="$root.moment($route.query.FirstDate, 'MM/DD/YYYY').day(2).format('MM/DD/YYYY')">
                                    <div v-if="row['past_add_cut']['T']=='false'">
                                        <!-- not past add cut -->
                                        <div v-if="row.tuesday_count!='0'"><a :href="`#`" @click.prevent.stop="(showDayView={orders:[], dow:'tuesday', ...row})">View ({{row.tuesday_count}})</a></div>
                                        <div v-else-if="$root.getCustomerValue('Web Orders Allowed Count')>0">
                                            <router-link v-if="checkAccountStatusBool()" :to="`/orders/${row['route no_']}/${row['location no_']}/create/edit?FirstDate=${$root.moment($route.query.FirstDate, 'MM/DD/YYYY').day(2).format('MM/DD/YYYY')}`" @click.prevent.stop="checkAccountStatus">Create</router-link>
                                            <div v-else><a href="#" @click.prevent.stop="checkAccountStatus">Create</a></div>
                                        </div>
                                        
                                    </div>
                                    <div v-else>
                                        <!-- past ad cut -->
                                        <div v-if="row.tuesday_count!='0'"><a :href="`#`" @click.prevent.stop="(showDayView={orders:[], dow:'tuesday', ...row})">View ({{row.tuesday_count}})</a></div>
                                        <div v-else></div>
                                    </div>
                                </td>
                                <td style="width: 110px;" :data-date="$root.moment($route.query.FirstDate, 'MM/DD/YYYY').day(3).format('MM/DD/YYYY')">
                                    <div v-if="row['past_add_cut']['W']=='false'">
                                        <!-- not past add cut -->
                                        <div v-if="row.wednesday_count!='0'"><a :href="`#`" @click.prevent.stop="(showDayView={orders:[], dow:'wednesday', ...row})">View ({{row.wednesday_count}})</a></div>
                                        <div v-else-if="$root.getCustomerValue('Web Orders Allowed Count')>0">
                                            <router-link v-if="checkAccountStatusBool()" :to="`/orders/${row['route no_']}/${row['location no_']}/create/edit?FirstDate=${$root.moment($route.query.FirstDate, 'MM/DD/YYYY').day(3).format('MM/DD/YYYY')}`" @click.prevent.stop="checkAccountStatus">Create</router-link>
                                            <div v-else><a href="#" @click.prevent.stop="checkAccountStatus">Create</a></div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <!-- past ad cut -->
                                        <div v-if="row.wednesday_count!='0'"><a :href="`#`" @click.prevent.stop="(showDayView={orders:[], dow:'wednesday', ...row})">View ({{row.wednesday_count}})</a></div>
                                        <div v-else></div>
                                    </div>
                                </td>
                                <td style="width: 110px;"  :data-date="$root.moment($route.query.FirstDate, 'MM/DD/YYYY').day(4).format('MM/DD/YYYY')">
                                    <div v-if="row['past_add_cut']['R']=='false'">
                                        <!-- not past add cut -->
                                        <div v-if="row.thursday_count!='0'"><a :href="`#`" @click.prevent.stop="(showDayView={orders:[], dow:'thursday', ...row})">View ({{row.thursday_count}})</a></div>
                                        <div v-else-if="$root.getCustomerValue('Web Orders Allowed Count')>0">
                                            <router-link v-if="checkAccountStatusBool()" :to="`/orders/${row['route no_']}/${row['location no_']}/create/edit?FirstDate=${$root.moment($route.query.FirstDate, 'MM/DD/YYYY').day(4).format('MM/DD/YYYY')}`" @click.prevent.stop="checkAccountStatus">Create</router-link>
                                            <div v-else><a href="#" @click.prevent.stop="checkAccountStatus">Create</a></div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <!-- past ad cut -->
                                        <div v-if="row.thursday_count!='0'"><a :href="`#`" @click.prevent.stop="(showDayView={orders:[], dow:'thursday', ...row})">View ({{row.thursday_count}})</a></div>
                                        <div v-else></div>
                                    </div>
                                </td>
                                <td style="width: 110px;" :data-date="$root.moment($route.query.FirstDate, 'MM/DD/YYYY').day(5).format('MM/DD/YYYY')">
                                    <div v-if="row['past_add_cut']['F']=='false'">
                                        <!-- not past add cut -->
                                        <div v-if="row.friday_count!='0'"><a :href="`#`" @click.prevent.stop="(showDayView={orders:[], dow:'friday', ...row})">View ({{row.friday_count}})</a></div>
                                        <div v-else-if="$root.getCustomerValue('Web Orders Allowed Count')>0">
                                            <router-link v-if="checkAccountStatusBool()" :to="`/orders/${row['route no_']}/${row['location no_']}/create/edit?FirstDate=${$root.moment($route.query.FirstDate, 'MM/DD/YYYY').day(5).format('MM/DD/YYYY')}`" @click.prevent.stop="checkAccountStatus">Create</router-link>
                                            <div v-else><a href="#" @click.prevent.stop="checkAccountStatus">Create</a></div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <!-- past ad cut -->
                                        <div v-if="row.friday_count!='0'"><a :href="`#`" @click.prevent.stop="(showDayView={orders:[], dow:'friday', ...row})">View ({{row.friday_count}})</a></div>
                                        <div v-else></div>
                                    </div>
                                </td>
                                <td style="width: 110px;" :data-date="$root.moment($route.query.FirstDate, 'MM/DD/YYYY').day(6).format('MM/DD/YYYY')" v-if="canOrderSaturday">
                                    <div v-if="row['past_add_cut']['S']=='false'">
                                        <!-- not past add cut -->
                                        <div v-if="row.saturday_count!='0'"><a :href="`#`" @click.prevent.stop="(showDayView={orders:[], dow:'saturday', ...row})">View ({{row.saturday_count}})</a></div>
                                        <div v-else-if="$root.getCustomerValue('Web Orders Allowed Count')>0">
                                            <router-link v-if="checkAccountStatusBool()" :to="`/orders/${row['route no_']}/${row['location no_']}/create/edit?FirstDate=${$root.moment($route.query.FirstDate, 'MM/DD/YYYY').day(6).format('MM/DD/YYYY')}`" @click.prevent.stop="checkAccountStatus">Create</router-link>
                                            <div v-else><a href="#" @click.prevent.stop="checkAccountStatus">Create</a></div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <!-- past ad cut -->
                                        <div v-if="row.saturday_count!='0'"><a :href="`#`" @click.prevent.stop="(showDayView={orders:[], dow:'saturday', ...row})">View ({{row.saturday_count}})</a></div>
                                        <div v-else></div>
                                    </div>
                                </td>
                                <td style="width: 110px;">
                                    {{row.total}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </ScrollingTable>
            
            <ScrollingTable :style="`position:sticky; top: 0; left: 0`" :scroll="true" v-if="$route.query.SummaryType=='ITEM'">
                <template v-slot:table-header>
                    <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                        <thead>
                            <tr>
                                <th title="Item" class="text-white" style=" background-color: var(--bs-gray-700); width: 110px;">
                                    <span>Item</span>
                                </th>
                                <th title="Description" class="text-white"  style=" background-color: var(--bs-gray-700); width: 150px;">
                                    <span>Description</span>
                                </th>
                                <th title="Monday" class="text-white"  style=" background-color: var(--bs-gray-700); width: 110px;">
                                    <span><small>Monday</small></span>
                                    <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='Monday'" :class="`${(filter.orderBy=='Monday'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='Monday'?'':'rotate')}`">
                                </th>
                                <th title="Tuesday" class="text-white"  style=" background-color: var(--bs-gray-700); width: 110px;">
                                    <span><small>Tuesday</small></span>
                                    <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='Tuesday'" :class="`${(filter.orderBy=='Tuesday'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='Tuesday'?'':'rotate')}`">
                                </th>
                                <th title="Wednesday" class="text-white"  style=" background-color: var(--bs-gray-700); width: 110px;">
                                    <span><small>Wednesday</small></span>
                                    <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='Wednesday'" :class="`${(filter.orderBy=='Wednesday'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='Wednesday'?'':'rotate')}`">
                                </th>
                                <th title="Thursday" class="text-white"  style=" background-color: var(--bs-gray-700); width: 110px;">
                                    <span><small>Thursday</small></span>
                                    <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='Thursday'" :class="`${(filter.orderBy=='Thursday'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='Thursday'?'':'rotate')}`">
                                </th>
                                <th title="Friday" class="text-white"  style=" background-color: var(--bs-gray-700); width: 110px;">
                                    <span><small>Friday</small></span>
                                    <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='Friday'" :class="`${(filter.orderBy=='Friday'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='Friday'?'':'rotate')}`">
                                </th>
                                <th title="Saturday" class="text-white"  style=" background-color: var(--bs-gray-700); width: 110px;" v-if="canOrderSaturday">
                                    <span><small>Saturday</small></span>
                                    <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='Saturday'" :class="`${(filter.orderBy=='Friday'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='Friday'?'':'rotate')}`">
                                </th>
                                <th title="Total" class="text-white"  style=" background-color: var(--bs-gray-700); width: 110px;">
                                    <span><small>Total</small></span>
                                    <img src="@/assets/chevron-down.svg" width="15" @click="filter.order=(filter.order=='asc'?'desc':'asc'); pagination.page=0; filter.orderBy='TOTAL'" :class="`${(filter.orderBy=='TOTAL'?'':'opacity-50')} ${(filter.order=='asc'&&filter.orderBy=='TOTAL'?'':'rotate')}`">
                                </th>
                            </tr>
                            <tr>
                                <th  style="background-color:var(--bs-gray-500); width: 110px;"><input autocomplete="off" autosuggest="off" @input="filterInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['item']"></th>
                                <th  style="background-color:var(--bs-gray-500); width: 150px;"><input autocomplete="off" autosuggest="off" @input="filterInput" placeholder="Filter..." class="form-control p-0 ps-2 pe-1" type="text" v-model="search['itmdesc']"></th>
                                <th  style="background-color:var(--bs-gray-500); width: 110px;">
                                    <div class="d-block form-check form-switch text-center">
                                        <input type="checkbox" v-model="search['Monday']" class="form-check-input float-none">
                                    </div>
                                </th>
                                <th  style="background-color:var(--bs-gray-500); width: 110px;">
                                    <div class="d-block form-check form-switch text-center">
                                        <input type="checkbox" v-model="search['Tuesday']" class="form-check-input float-none">
                                    </div>
                                </th>
                                <th  style="background-color:var(--bs-gray-500); width: 110px;">
                                    <div class="d-block form-check form-switch text-center">
                                        <input type="checkbox" v-model="search['Wednesday']" class="form-check-input float-none">
                                    </div>
                                </th>
                                <th  style="background-color:var(--bs-gray-500); width: 110px;">
                                    <div class="d-block form-check form-switch text-center">
                                        <input type="checkbox" v-model="search['Thursday']" class="form-check-input float-none">
                                    </div>
                                </th>
                                <th  style="background-color:var(--bs-gray-500); width: 110px;">
                                    <div class="d-block form-check form-switch text-center">
                                        <input type="checkbox" v-model="search['Friday']" class="form-check-input float-none">
                                    </div>
                                </th>
                                <th  style="background-color:var(--bs-gray-500); width: 110px;" v-if="canOrderSaturday">
                                    <div class="d-block form-check form-switch text-center">
                                        <input type="checkbox" v-model="search['Saturday']" class="form-check-input float-none">
                                    </div>
                                </th>
                                <th  style="background-color:var(--bs-gray-500); width: 110px;">
                                    <div class="d-block form-check form-switch text-center">
                                        <input type="checkbox" v-model="search['TOTAL']" class="form-check-input float-none">
                                    </div>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </template>
                <template  v-slot:table-body>
                    <table class=" mt-0 mb-0 min-w-auto table table-bordered table-striped table-sm" style="">
                        <tbody>
                            <tr v-for="(row, i) in  _.filter(_.orderBy(rows, filter.orderBy, filter.order), r=>{
                                return r['item'].toLowerCase().startsWith(search['item'].toLowerCase().trim())&&
                                new RegExp(search['itmdesc'].toLowerCase().trim(), 'gi').test(r['itmdesc'].toLowerCase())&&  
                                (search['Monday']?r['Monday']!=0:(r['Monday']!=0||r['Monday']==0))&&
                                (search['Tuesday']?r['Tuesday']!=0:(r['Tuesday']!=0||r['Tuesday']==0))&&
                                (search['Wednesday']?r['Wednesday']!=0:(r['Wednesday']!=0||r['Wednesday']==0))&&
                                (search['Thursday']?r['Thursday']!=0:(r['Thursday']!=0||r['Thursday']==0))&&
                                (search['Friday']?r['Friday']!=0:(r['Friday']!=0||r['Friday']==0))&&
                                (search['Saturday']?r['Friday']!=0:(r['Saturday']!=0||r['Saturday']==0))&&
                                (search['TOTAL']?r['TOTAL']!=0:(r['TOTAL']!=0||r['TOTAL']==0));
                            })" :key="i">
                                <td style="width: 110px;"><a :href="`${this.$store.state.api}/pdfs/iteminfo.php?item_no=${row.item}`" class="text-black" target="_blank">{{row.item}}</a></td>
                                <td style="width: 150px;">
                                    <a :href="`${this.$store.state.api}/pdfs/iteminfo.php?item_no=${row.item}`" class="text-black" target="_blank" v-html="row.itmdesc"></a>
                                </td>
                                <td style="width: 110px;">{{+(row['Monday']==0?'0':+(row['Monday']))}}</td>
                                <td style="width: 110px;">{{+(row['Tuesday']==0?'0':+(row['Tuesday']))}}</td>
                                <td style="width: 110px;">{{+(row['Wednesday']==0?'0':+(row['Wednesday']))}}</td>
                                <td style="width: 110px;">{{+(row['Thursday']==0?'0':+(row['Thursday']))}}</td>
                                <td style="width: 110px;">{{+(row['Friday']==0?'0':+(row['Friday']))}}</td>
                                <td v-if="canOrderSaturday" style="width: 110px;">{{+(row['Saturday']==0?'0':+(row['Saturday']))}}</td>
                                <td style="width: 110px;">{{+(row['TOTAL']==0?'0':+(row['TOTAL']))}}</td>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </ScrollingTable>
            <!-- {{ rows }} -->
            <div data-intro="Item quantity totals by day" class="">
                <History :canOrderSaturday="canOrderSaturday" :type="$route.query.SummaryType" :rows="rows" />
            </div>
            
        </div>
        <teleport to="body"  v-if="showDayView">
            <div class="modal show" id="myModal" style="display:block;">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <!-- {{ showDayView }} -->
                        <h4 class="modal-title">Orders for
                            <span v-if="showDayView.dow=='sunday'">{{$root.moment($route.query.FirstDate,'MM/DD/YYYY').day(0).format('MM/DD/YYYY')}}</span>
                            <span v-else-if="showDayView.dow=='monday'">{{$root.moment($route.query.FirstDate,'MM/DD/YYYY').day(1).format('MM/DD/YYYY')}}</span>
                            <span v-else-if="showDayView.dow=='tuesday'">{{$root.moment($route.query.FirstDate,'MM/DD/YYYY').day(2).format('MM/DD/YYYY')}}</span>
                            <span v-else-if="showDayView.dow=='wednesday'">{{$root.moment($route.query.FirstDate,'MM/DD/YYYY').day(3).format('MM/DD/YYYY')}}</span>
                            <span v-else-if="showDayView.dow=='thursday'">{{$root.moment($route.query.FirstDate,'MM/DD/YYYY').day(4).format('MM/DD/YYYY')}}</span>
                            <span v-else-if="showDayView.dow=='friday'">{{$root.moment($route.query.FirstDate,'MM/DD/YYYY').day(5).format('MM/DD/YYYY')}}</span>
                            <span v-else-if="showDayView.dow=='saturday'">{{$root.moment($route.query.FirstDate,'MM/DD/YYYY').day(6).format('MM/DD/YYYY')}}</span>
                        </h4>
                        <button type="button" class="btn-close"  @click.prevent.stop="showDayView=false"></button>
                    </div>

                    <!-- Modal body -->
                    <div class="modal-body">
                        <div class="text-center p-3" v-if="(showDayView.orders.length==0)" style="line-height:1.2">
                            <div class="mb-3 text-start">
                                <small>
                                    <b>Fetching Orders for: </b><br>
                                    <span v-if="showDayView.dow=='sunday'">{{$root.moment($route.query.FirstDate,'MM/DD/YYYY').day(0).format('MM/DD/YYYY')}}</span>
                                    <span v-else-if="showDayView.dow=='monday'">{{$root.moment($route.query.FirstDate,'MM/DD/YYYY').day(1).format('MM/DD/YYYY')}}</span>
                                    <span v-else-if="showDayView.dow=='tuesday'">{{$root.moment($route.query.FirstDate,'MM/DD/YYYY').day(2).format('MM/DD/YYYY')}}</span>
                                    <span v-else-if="showDayView.dow=='wednesday'">{{$root.moment($route.query.FirstDate,'MM/DD/YYYY').day(3).format('MM/DD/YYYY')}}</span>
                                    <span v-else-if="showDayView.dow=='thursday'">{{$root.moment($route.query.FirstDate,'MM/DD/YYYY').day(4).format('MM/DD/YYYY')}}</span>
                                    <span v-else-if="showDayView.dow=='friday'">{{$root.moment($route.query.FirstDate,'MM/DD/YYYY').day(5).format('MM/DD/YYYY')}}</span>
                                    <span v-else-if="showDayView.dow=='saturday'">{{$root.moment($route.query.FirstDate,'MM/DD/YYYY').day(6).format('MM/DD/YYYY')}}</span><br>
                                    Location Number: {{showDayView['location no_']}}<br>
                                    Route Number: {{showDayView['route no_']}}<br>
                                </small>
                            </div>
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        <div v-else>
                            <div class="mb-3">
                                <small>
                                    <b>Orders for: </b><br>
                                    <span v-if="showDayView.dow=='sunday'">{{$root.moment($route.query.FirstDate,'MM/DD/YYYY').day(0).format('MM/DD/YYYY')}}</span>
                                    <span v-else-if="showDayView.dow=='monday'">{{$root.moment($route.query.FirstDate,'MM/DD/YYYY').day(1).format('MM/DD/YYYY')}}</span>
                                    <span v-else-if="showDayView.dow=='tuesday'">{{$root.moment($route.query.FirstDate,'MM/DD/YYYY').day(2).format('MM/DD/YYYY')}}</span>
                                    <span v-else-if="showDayView.dow=='wednesday'">{{$root.moment($route.query.FirstDate,'MM/DD/YYYY').day(3).format('MM/DD/YYYY')}}</span>
                                    <span v-else-if="showDayView.dow=='thursday'">{{$root.moment($route.query.FirstDate,'MM/DD/YYYY').day(4).format('MM/DD/YYYY')}}</span>
                                    <span v-else-if="showDayView.dow=='friday'">{{$root.moment($route.query.FirstDate,'MM/DD/YYYY').day(5).format('MM/DD/YYYY')}}</span>
                                    <span v-else-if="showDayView.dow=='saturday'">{{$root.moment($route.query.FirstDate,'MM/DD/YYYY').day(6).format('MM/DD/YYYY')}}</span><br>
                                    Location Number: {{showDayView['location no_']}}<br>
                                    Route Number: {{showDayView['route no_']}}<br>
                                </small>
                            </div>
                            <table class="w-100 table table-bordered table-striped table-sm">
                                <thead>
                                    <tr>
                                <!-- /orders/:route_no/:location_no/:document_no/edit -->
                                        <th title="Doc #" class="text-white" style=" background-color: var(--bs-gray-700);">
                                            <span>Doc #</span>
                                        </th>
                                        <th title="Used On Day" class="text-white" style=" background-color: var(--bs-gray-700);">
                                            <span>Used On Day</span>
                                        </th>
                                        <th title="Qty" class="text-white" style=" background-color: var(--bs-gray-700);">
                                            <span>Qty</span>
                                        </th>
                                        <th title="" class="text-white text-end" style=" width:75px; background-color: var(--bs-gray-700);">
                                            <span></span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(order,j) in showDayView.orders" :key="j">
                                        <td class="align-middle"><a class="text-dark text-decoration-none" :href="`/orders/${showDayView['route no_']}/${showDayView['location no_']}/${order['document no_']}/edit`">{{order['document no_']}}</a></td>
                                        <td class="align-middle"><a class="text-dark text-decoration-none" :href="`/orders/${showDayView['route no_']}/${showDayView['location no_']}/${order['document no_']}/edit`">{{order['used on day']}}</a></td>
                                        <td class="align-middle"><a class="text-dark text-decoration-none" :href="`/orders/${showDayView['route no_']}/${showDayView['location no_']}/${order['document no_']}/edit`">{{order['qty']}}</a></td>
                                        <td class="text-end align-middle"><a :href="`/orders/${showDayView['route no_']}/${showDayView['location no_']}/${order['document no_']}/edit?FirstDate=${$root.moment(order['document date'].date).format('MM/DD/YYYY')}`" class="btn btn-secondary btn-sm">{{ showDayView.past_add_cut[(showDayView.dow=='thursday'?'R':showDayView.dow[0].toUpperCase())] == 'true' ? 'View':'Edit' }}</a></td>
                                    </tr> 
                                </tbody>
                            </table>
                            <div class="mt-2 text-center"  v-if="!isPastAddCut(showDayView)">
                                <div v-if="$root.getCustomerValue('Web Orders Allowed Count')>showDayView.orders.length">
                                    <a class="btn btn-secondary btn-sm" :href="`/orders/${showDayView['route no_']}/${showDayView['location no_']}/create/edit?FirstDate=${$root.moment(showDayView.orders[0]['document date'].date).day(['sunday','monday','tuesday','wednesday','thursday','friday','saturday'][['sunday','monday','tuesday','wednesday','thursday','friday','saturday'].indexOf(showDayView.dow)]).format('MM/DD/YYYY')}`" @click.prevent.stop="checkAccountStatus">Create New Order</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Modal footer -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click.prevent.stop="showDayView=false">Close</button>
                    </div>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop show"></div>
        </teleport>
    </PageContent>
  </template>
  
  <script>
    import PageContent from '@/components/PageContent.vue';
    import NotReady from '@/components/NotReady.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import ScrollingTable from '@/components/Table/ScrollingTable.vue';
    import Modal from '@/components/Modal.vue';
    import Help from '@/components/Help.vue';
    import VueSimpleAlert from 'vue3-simple-alert';
    import History from '@/components/OrderSummary/History.vue';
import axios from 'axios';

  export default {
    name: 'OrderSummary',
    // components: {PageContent, PageTitle, Table, TableRow, TableCell, NotReady, TableHead, History},
    components: {PageContent, PageTitle, NotReady, History, Modal, ScrollingTable, Help},
    watch:{
        showDayView:async function(newVal, oldVal){
            if(newVal != false && newVal.orders.length==0){
                let res = await axios.get(`${this.$store.state.api}/ordersbyday?lccustno=${newVal['customer no_']}&location_no=${newVal['location no_']}&route_no=${newVal['route no_']}&orderdate=${(()=>{
                    let date = '';
                    if(newVal.dow=='sunday') date = this.$root.moment(this.$route.query.FirstDate).day(0).format('MM/DD/YYYY');
                    if(newVal.dow=='monday') date = this.$root.moment(this.$route.query.FirstDate).day(1).format('MM/DD/YYYY');
                    if(newVal.dow=='tuesday') date = this.$root.moment(this.$route.query.FirstDate).day(2).format('MM/DD/YYYY');
                    if(newVal.dow=='wednesday') date = this.$root.moment(this.$route.query.FirstDate).day(3).format('MM/DD/YYYY');
                    if(newVal.dow=='thursday') date = this.$root.moment(this.$route.query.FirstDate).day(4).format('MM/DD/YYYY');
                    if(newVal.dow=='friday') date = this.$root.moment(this.$route.query.FirstDate).day(5).format('MM/DD/YYYY');
                    if(newVal.dow=='saturday') date = this.$root.moment(this.$route.query.FirstDate).day(6).format('MM/DD/YYYY');
                    return date;
                })()}`);
                newVal.orders = res;
            }
        }
    },
    data(){
        return {
            _:_,
            ready:false,
            disabled:false,
            rows:[],
            canOrderSaturday:false,
            search:{
                searchString:['true'],
                'route no_':'',
                'location no_':'',
                'name':'',
                'monday_count':'',
                'tuesday_count':'',
                'wednesday_count':'',
                'thursday_count':'',
                'friday_count':'',
                'saturday_count':'',
                'total':'',
                'item':'',
                'itmdesc':''
            },
            filter:{
                orderBy:'route no_',
                order:'asc'
            },
            pagination:{
                search:'',
                pages:0,
                per_page:20,
                page:0,
            },
            showDayView:false,
            dayViewOrders:[],
            dayViewDay:'',
            dayViewLocation:'',
            dayViewRoute:'',
            viewOrderDay:false,
        }
    },
    async mounted(){
        if(this.$route.query.FirstDate==undefined) this.$route.query.FirstDate = this.$root.moment().startOf('week').format('MM/DD/YYYY');
        await this.getLocations();
        
        this.ready = true;
    },
    methods:{
        
        checkAccountStatusBool(){
            if(localStorage.account_status!='Credit Hold (Over Limit)'){
                return true;
            }
            else{
                return false;
            }
        },
        async checkAccountStatus(e){
            let url = e.target.href;
            if(localStorage.account_status!='Credit Hold (Over Limit)'){
                this.$router.push(url.replace('http://localhost:8080','').replace('http://localhost:8081','').replace('https://orders.kffoods.com','').replace('https://dev.kffoods.com',''));
            }
            else{
                this.$root.showToast(['alert', 'alert-danger', 'bg-danger'], 'Credit Hold: You cannot place an order at this time.');
                // await Sleep(300);
                // this.$router.go(-1);
                e.preventDefault();
            }
        },
        isPastAddCut(d){
            let letter;
            let day;
            day = (this.$root.moment(d.orders[0]['document date'].date).format('ddd'))
            if(day=='Thu') letter = 'R';
            else letter = day[0];

            if(d.past_add_cut[letter]=='true') return true;
            else return false;
        },
        getOrdersCountLinks(d, location){
            let output = ``;
            _.forEach(location, day=>{
                _.forEach(day, order=>{
                    if(d==order['used on day']){
                        output += `
                            <div class="border-bottom">
                                <small><a href="/orders/${order['Route No_']}/${order['Location No_']}/${order['document no_']}/edit/?FirstDate=${this.$route.query.FirstDate}&lcrouteno=${order['Route No_']}&lclocationno=${order['Location No_']}">${order['document no_']}</a> - (Qty: ${order['qty']})</small>
                            </div>
                        `;
                    }
                })
            })
            
            return output;
        },
        getOrderEditNoLink(row){
            let num = Number(row);
            num = num.toFixed(0);
            if(num > 0){
                return `${num}`;
            }
            else return num;
        },
        getOrderEditLink(row, day,obj){
            let num = Number(row);
            num = num.toFixed(0);
            if(num > 0){
                return `<a href="/OrderSummary/${obj.numbers[day]}/edit?route_no=${obj['route no_']}&location_no=${obj['location no_']}&date=${this.$route.query.FirstDate}&SummaryType=${this.$route.query.SummaryType?this.$route.query.SummaryType:'LOCATION'}">${num}</a>`;
            }
            else return num;
        },
        async loadMore(){
            this.pagination.page += 1;
        },
        filterInput(e){
          this.pagination.page=0;
        },
        async getLocations(){
            return new Promise(async resolve=>{
                let res = await axios.get(`${this.$store.state.api}/ordersummary${this.$route.query.SummaryType==undefined?'LOCATION':`${this.$route.query.SummaryType}`}?&lccustno=${this.$root.getCustomerNumber()}&FirstDate=${this.$route.query.FirstDate?this.$route.query.FirstDate:this.$root.moment().day(0).format('MM/DD/YYYY')}`);
                this.rows = res;

                if(!this.$route.query.SummaryType||this.$route.query.SummaryType=='LOCATION'){
                    this.rows = _.map(this.rows, r=>({...r, ...{
                        monday_count:+r.monday_count,
                        tuesday_count:+r.tuesday_count,
                        wednesday_count:+r.wednesday_count,
                        thursday_count:+r.thursday_count,
                        friday_count:+r.friday_count,
                        saturday_count:+r.saturday_count,
                    }}));
                    if(this.rows.filter(r=>r['Location Menu Days'].indexOf('S')!=-1).length){
                        this.canOrderSaturday = true;
                    }
                }
                else{
                    this.rows = _.map(this.rows, r=>({...r, ...{
                        Monday:+r.Monday,
                        Tuesday:+r.Tuesday,
                        Wednesday:+r.Wednesday,
                        Thursday:+r.Thursday,
                        Friday:+r.Friday,
                        Saturday:+r.Saturday,
                        TOTAL:+r.TOTAL,
                    }}));
                    this.rows.forEach(r=>{
                        if(+r.Saturday > 0){
                            this.canOrderSaturday = true;
                        }
                    })
                }

                
                resolve();
            })
        },
        async showDay(day){
        },
    },
  }
  </script>
  <style lang="scss" scoped>
    table{
        thead{
            tr{
                th{
                    svg{
                        &.rotate{
                            transform: rotate(180deg);
                        }
                    }
                    svg path{
                        fill:#000 !important;
                        cursor:pointer;
                    }
                }
            }
        }
        tbody{
            tr{
                td:nth-of-type(1){
                    svg{
                        cursor:pointer;
                    }
                }
            }
        }
    }
    table{
        font-size: 14px;
    }    
    .is-responsive{
        @media (max-width:  992px){
            [data-title="OrderSummary"]{
                .table-body-parent{
                table.table{
                    // border: 5px solid red !important
                    display:block;
                    > tbody{
                    display:block;
                    tr{
                        display:block;
                        margin-bottom: 0.5rem  !important;
                        // padding: 0.75rem !important;
                        border: 1px solid #000;

                        td{
                        display:block;
                        width: 100% !important;
                        padding-top: 0;
                        padding-bottom: 0;
                        border-left-width: 0px;
                        border-right-width: 0px;
                        &:nth-of-type(1){
                            &:before{
                            content:  'Route: ';
                            font-weight:bold;
                            }
                        }
                        &:nth-of-type(2){
                            &:before{
                            content:  'Location: ';
                            font-weight:bold;
                            }
                        }
                        &:nth-of-type(3){
                            &:before{
                            content:  'Description: ';
                            font-weight:bold;
                            }
                        }
                        &:nth-of-type(4){
                            &:before{
                                content:'Monday, ' attr(data-date) ': ';
                                font-weight:bold;
                                text-decoration:none;
                                color:#000;
                                display:block;
                                border-bottom: 1px solid #000;
                            }
                        }
                        &:nth-of-type(5){
                            &:before{
                                content:'Tuesday, ' attr(data-date) ': ';
                                font-weight:bold;
                                text-decoration:none;
                                color:#000;
                                display:block;
                                border-bottom: 1px solid #000;
                            }
                        }
                        &:nth-of-type(6){
                            &:before{
                                content:'Wednesday: ' attr(data-date) ': ';
                                font-weight:bold;
                                text-decoration:none;
                                color:#000;
                                display:block;
                                border-bottom: 1px solid #000;
                            }
                        }
                        &:nth-of-type(7){
                            &:before{
                                content:'Thursday: ' attr(data-date) ': ';
                                font-weight:bold;
                                text-decoration:none;
                                color:#000;
                                display:block;
                                border-bottom: 1px solid #000;
                            }
                        }
                        &:nth-of-type(8){
                            &:before{
                                content:'Friday: ' attr(data-date) ': ';
                                font-weight:bold;
                                text-decoration:none;
                                color:#000;
                                display:block;
                                border-bottom: 1px solid #000;
                            }
                        }
                        &:nth-of-type(9){
                            &:before{
                                content:'Total: ';
                                font-weight:bold;
                                text-decoration:none;
                                color:#000;
                            }
                        }
                        &:last-child{
                            .btn{
                            }
                        }
                        }
                    }
                    }
                }
                }
            }
            }
    }
    
  </style>