<template>
    <PageContent>
        <PageTitle>Delivery Instructions</PageTitle>
        <table class="table table-sm">
            <thead>
                <th></th>
                <th>Customer</th>
                <th>Ship-to</th>
                <th>City</th>
            </thead>
            <tbody>
                <tr v-for="shipto in shipTos">
                    <td><button @click.prevent.stop="getInstructions(`${$store.state.api}/delivery-inst?deliveryShipTo=${shipto['ship-to code']}&lccustno=${shipto['customer no_']}`)" class="btn btn-secondary btn-sm">View</button></td>
                    <td>{{shipto['customer no_']}} {{ shipto.name }}</td>
                    <td>{{ shipto['ship-to code'] }}</td>
                    <td>{{ shipto['city'] }}</td>
                </tr>
            </tbody>
        </table>
        <div v-html="content"></div>
    </PageContent>
    
  </template>
  <script>
  import PageContent from '@/components/PageContent.vue';
  import NotReady from '@/components/NotReady.vue';
  import PageTitle from '@/components/PageTitle.vue';
import axios from 'axios';
  export default {
    name: 'DeliveryInstructions',
    components: {PageContent, PageTitle,  NotReady},
    data(){
        return {
            ready:false,
            disabled:false,
            content:'',
            shipTos:[],
        }
    },
    async mounted(){
        let res = await axios.get(`${this.$store.state.api}/delivery-inst-shiptos/?lccustno=${this.$root.getCustomerNumber()}&name=${this.$root.getCustomerValue('Name')}`);
        this.shipTos = _.orderBy(res, ship=>ship['ship-to code'].toLowerCase());
    },
    methods:{
        async getInstructions(url){
            let res = await axios.get(`${url}`);
            this.content = res.replace(/\\n/gi,'<br>');
            this.content = this.content.replace(/\\t/gi,'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;');
            this.content = this.content.replace(/\\/gi,'"');
            console.log(this.content)
        }
    },
  }
  </script>
  <style lang="scss" scoped>
  @media (max-width:  992px){
        table{
            thead{
                display:none;
            }
            tbody{
                tr{
                    display:flex;
                    flex-wrap:wrap;
                    
                    padding: 0.75rem;
                    margin-bottom: 0.5rem;
                    border: 1px solid #DEE2E6;
                    &:nth-of-type(even){
                        background-color:#f2f2f2;
                    }
                    td{
                        display:block;
                        width: 100%;
                        border: none !important;
                        padding: 0;
                        padding-bottom: 0.25rem;
                        &:nth-of-type(1){
                            &:before{

                            }
                            order: 10;
                        }
                        &:nth-of-type(2){
                            &:before{
                                content:'Customer: ';
                                font-weight:bold;
                            }
                            order: 1;
                        }
                        &:nth-of-type(3){
                            &:before{
                                content:'Ship-to: ';
                                font-weight:bold;
                            }
                            order: 2;
                        }
                        &:nth-of-type(4){
                            &:before{
                                content:'City: ';
                                font-weight:bold;
                            }
                            order: 3;
                        }
                    }
                }
            }
        }
    }
        
  </style>